import moment from "moment";

/* eslint-disable prettier/prettier */
export const utilMixin = {
  methods: {
    getColor(string: string) {
      const char = string.split("")[0].toUpperCase();

      switch (char) {
        case "A":
          return "#CE93D8";
        case "B":
          return "#F48FB1";
        case "C":
          return "#B39DDB";
        case "D":
          return "#9FA8DA";
        case "E":
          return "#90CAF9";
        case "F":
          return "#81D4FA";
        case "G":
          return "#80DEEA";
        case "H":
          return "#80CBC4";
        case "I":
          return "#A5D6A7";
        case "J":
          return "#C5E1A5";
        case "K":
          return "#E6EE9C";
        case "L":
          return "#FFF59D";
        case "M":
          return "#BEA044";
        case "N":
          return "#FFCC80";
        case "O":
          return "#FFAB91";
        case "P":
          return "#916F63";
        case "Q":
          return "#B0BEC5";
        case "R":
          return "#E57373";
        case "S":
          return "#F06292";
        case "T":
          return "#BA68C8";
        case "U":
          return "#9575CD";
        case "V":
          return "#7986CB";
        case "W":
          return "#64B5F6";
        case "X":
          return "#4FC3F7";
        case "Y":
          return "#4DD0E1";
        case "Z":
          return "#4DB6AC";
        default:
          return "#EF9A9A";
      }
    },
    getDate(date: string) {
      const d = moment(date);
      return d.format("M/DD/yyyy");
    },
    getColorStatus(status: string) {
      switch (status) {
        case "ACTIVE":
          return "#00E676";

        default:
          return "#E64A19";
      }
    },
  },
};
